.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.cursor:hover {
  cursor: pointer;
}
.slide-right-hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(100%);
  transition: all 1s;
}
.slide-right-show {
  opacity: 1;
  transform: translateX(0);
  filter: blur(0);
}
.slide-down-hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateY(-10%);
  transition: all 1s;
}
.slide-down-show {
  opacity: 1;
  transform: translateY(0);
  filter: blur(0);
}
.slide-down-hidden-img {
  opacity: 0;
  filter: blur(5px);
  transform: translateY(-5%);
  transition: all 1s;
}
.slide-down-show-img {
  opacity: 1;
  transform: translateY(0);
  filter: blur(0);
}
.slide-left-hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 1s;
}
.slide-left-show {
  opacity: 1;
  transform: translateX(0);
  filter: blur(0);
}
.gradient {
  background: #121fcf;
  background: linear-gradient(to right, #650101 0%, #fa0601 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-hover {
  cursor: pointer;
  background-image: linear-gradient(to right, crimson, pink, springgreen);
  color: black;
  display: inline-block;
  text-transform: uppercase;
  transition: color 0.2s ease-in-out, transform 1s;
  background-size: 200% 100%;
  animation: rainbow 2s ease-in-out infinite;

  -webkit-background-clip: text;
}
.text-hover:hover {
  color: rgba(0, 0, 0, 0);
  transform: scale(1.03);
  transition: all 0.1s;
}

@keyframes rainbow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.gradient-text {
  font-family: "Abril Fatface", sans-serif;
  background: linear-gradient(to right, crimson, pink, springgreen);
  background-size: 200% 200%;
  animation: rainbow 2s ease-in-out infinite;
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 1);
  font-size: 10rem;
  width: 80vw;
  margin: 30vh auto;
  display: block;
  text-align: center;
  transition: color 0.2s ease-in-out;
  text-transform: uppercase;
  font-weight: 900;

  /* transition: all 1s ease; */
}
.gradient-text:hover {
  color: rgba(0, 0, 0, 0);
}
.img-hov:hover {
  transform: scale(1.01);
  transition: all 0.1s;
}
/* .bg-img-1 {
  background-image: url("./resources/wedding/Carousel_1.jpg");
}
.bg-img-2 {
  background-image: url("./resources/wedding/Carousel_2.jpg");
}
.bg-img-3 {
  background-image: url("./resources/wedding/Carousel_3.jpg");
}
.bg-img-4 {
  background-image: url("./resources/wedding/Carousel_4.jpg");
} */
/* .bg-img-maternity { */
/* background-image: url("./resources/maternity/Priscila-73.jpg"); */
/* } */
.carousel-image {
  /* height: 100vh; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.instagram {
  /* color: linear-gradient(to right, #f9ce34, #ee2a7b, #6228d7); */
  /* background-size: 200% 200%; */
  /* background-clip: text; */
  /* -webkit-background-clip: text; */
  /* font-size: 2em;
  display: block;
  text-align: center; */
  /* transition: color 0.2s ease-in-out; */
  /* text-transform: uppercase; */
  /* font-weight: 900; */
}
